<template>
  <div class="">
    <!-- write up part -->
    <div class="row justify-content-center ">
      <div class="col-md-9 mt-3 ">
        <div class="col-md-12 d-flex  cursor-pointer" @click="watchVideo"> <el-icon :size="24" ><VideoPlay /></el-icon><span class="primary-text">Watch this video on how to setup</span></div>
        <div class="row ">
          <div class="col-md-3"></div>
          <div class=" col-md-7 justify-content-center d-flex text-center h2 font-weight-600  setup mt-5 text-head">Set
            up
            Church
            Profile</div>
          <!-- <div class="col-sm-3"></div> -->
          <div
            class=" col-12 col-md-3 text-font   font-weight-600 align-self-center mt-4 text-left text-sm-left text-md-right ">
            Church Name</div>
          <div class="col-12 col-sm-12 col-md-7 mt-4">
            <!-- <input type="text" class="form-control" v-model="churchName" /> -->
            <el-input type="text" class="w-100" v-model="churchName" />
          </div>

          <div
            class="col-12 col-sm-12 col-md-3 col-lg-3 text-font font-weight-600 align-self-center mt-4 text-left text-sm-left text-md-right text-lg-right">
            Phone Number
          </div>
          <div class="col-12 col-sm-12 col-md-7 col-lg-7 mt-4">
            <!-- <input type="text" class="form-control" v-model="phoneNumber" /> -->
            <el-input type="text" class="w-100" v-model="phoneNumber" />
          </div>

          <div
            class="col-sm-12 col-md-3 col-lg-3 text-font font-weight-600 align-self-center mt-4 text-left text-sm-left text-md-right text-lg-right">
            Address</div>
          <div class="col-12 col-sm-12 col-md-7 col-lg-7 mt-4">
            <!-- <input type="text" class="form-control" v-model="address" /> -->
            <el-input type="text" class="w-100" v-model="address" />
          </div>
          <div class="col-md-12  mt-2 ">
            <div class="row justify-content-center">
              <div class="col-md-1"></div>
              <div class="col-md-7   mt-3">
                <div class="row justify-content-between">
                  <div class=" col-md-6 col-12 mt-1 text-font font-weight-600">Other Information</div>
                  <div class="col-12  col-md-4  c-pointer" @click="otherInfoForm" data-whatever="@fat">
                    <el-button round size="large" class=" c-pointer">Add</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- other information add button -->
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-md-2"></div>
              <div class="col-12 col-md-8  ">
                <div class="row" v-for="(item, index) in infoArray" :key="index">
                  <div class="col-md-10 px-0">
                    <div class=" bg-white py-2 rounded-lg col-md-12 mt-4  text-font font-weight-600">
                      <span class="display:block">{{ item.title }}</span>
                    </div>
                    <div class="col-12 pb-2 pt-3 rounded-lg  mt-1 bg-white">
                      <p>
                        <span v-if="!item.showFullMessage" class="display:block">{{
          item.details &&
            item.details.toString().length > 20
            ? `${item.details.substring(0, 20)}...`
            : item.details
        }}</span>
                        <span v-else>{{ item.details }}</span>
                        <span @click="() => (item.showFullMessage = !item.showFullMessage)
          " v-if="item.details && item.details.toString().length > 20" class="text-primary c-pointer pl-2">
                          {{ item.showFullMessage ? "see less" : "see more" }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-2 d-flex">
                    <div class=" mr-3  align-self-center cursor-pointer " data-target="#other-info" data-toggle="modal"
                      data-whatever="@fat" @click.prevent="editInfo(item)">
                      <i class="fa fa-pencil text-secondary"></i>
                    </div>
                    <div class=" align-self-center  cursor-pointer"
                      @click.prevent="showConfirmModalAbout(item.customAboutId, index)">
                      <i class="pi pi-trash text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-12  mt-4 ">
            <div class="row">
              <div class="col-12 col-sm-5 col-md-5 col-lg-5  font-weight-bold">Pastors and Ministers</div>
              <div class="col-12 col-sm-2 col-md-2 col-lg-2 mt-2  " data-target="#add-pastor" data-toggle="modal"
                data-whatever="@fat">
                <el-button class="c-pointer" round size="large"> Add</el-button>
              </div>
            </div>
          </div> -->
          <div class="col-md-12  mt-2 ">
            <div class="row justify-content-center">
              <div class="col-md-1"></div>
              <div class="col-md-7   mt-3">
                <div class="row justify-content-between">
                  <div class=" col-md-6 col-12 mt-1 text-font font-weight-600">Pastors and Ministers</div>
                  <div class="col-12  col-md-4  c-pointer" @click="pastorForm" data-whatever="@fat">
                    <el-button round size="large" class=" c-pointer">Add</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 ">
            <div class="row justify-content-center" v-for="(item, index) in pastors" :key="index">
              <div class="col-md-2"></div>
              <div class="col-12 col-md-8 ">
                <div class="row">
                  <div class="col-md-10 px-0 bg-white p-2   mt-4">
                    <div class="row">
                      <div class="col-12 col-md-5 person-image">
                        <img v-if="item.photo"
                          style="width: 110px; height: 110px; border-radius: 50%; object-fit: cover;"
                          :src="item.photo" />
                      </div>
                      <div class=" col-12 col-md-7 px-0 align-self-center  ">
                        <div class="text-font font-weight-600">{{ item.title ? item.title : '' }}</div>
                        <div class="text-font font-weight-600">{{ item.name }}</div>
                        <div>{{ item.bio }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 d-flex">
                    <div class=" mr-3 align-self-center cursor-pointer " data-whatever="@fat"
                      @click.prevent="editPastor(item.pastorId, index)">
                      <i class="fa fa-pencil text-secondary"></i>
                    </div>
                    <div class=" align-self-center cursor-pointer  "
                      @click.prevent="showConfirmModal(item.pastorId, index)">
                      <i class="pi pi-trash text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12  mt-2 ">
            <div class="row justify-content-center">
              <div class="col-md-1"></div>
              <div class="col-md-7   mt-4">
                <div class="row justify-content-between">
                  <div class=" col-md-6 col-12 mt-2 text-font font-weight-600">Branches</div>
                  <div class="col-12  col-md-4 c-pointer" data-whatever="@fat" @click="clearChurchBranch">
                    <el-button round class=" c-pointer"> Add</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12  mt-3">
            <div class="row  ">
              <div class="col-sm-5 col-md-5 col-lg-5 col-12 font-weight-600">Branches</div>
              <div class="col-12 mt-2 col-md-2 col-sm-2 col-lg-2 c-pointer" data-target="#branches" data-toggle="modal"
                data-whatever="@fat" @click="clearChurchBranch">
                <el-button round class=" c-pointer"> Add</el-button>
              </div>
            </div>
          </div> -->
          <!-- returnedObject for churchesbranch -->
          <div class="col-md-12  ">
            <div class="row justify-content-center " v-for="(item, index) in churchBranches" :key="index">
              <!-- <div class="col-sm-12 text-right align-self-center mt-2"></div> -->
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-12 col-md-10   py-3 rounded  bg-white   mt-4">
                    <div class="row  ">
                      <div class="col-md-5 col-12">
                        <div class="font-weight-600  text-font"><img src="../../../assets/mobileonboarding/House.png"
                            alt=""><span>{{ item.branchName }}</span></div>
                        <div class="text-font">{{ item.address }}</div>
                      </div>
                      <div class="col-12 col-md-7  align-self-center">
                        <div class="row">
                          <div class="col-12  col-md-4 px-0 " v-if="item.pastorPictureUrl">
                            <img style="width: 50px; height: 50px; border-radius: 50%; object-fit: cover;"
                              :src="item.pastorPictureUrl" />
                          </div>
                          <div class="col-md-8 col-12  px-0 ">
                            <div class="text-font  font-weight-600">{{ item.branchName }}</div>
                            <div class="text-font ">{{ item.pastorName }}</div>
                            <div class="text-font  d-flex"><img src="../../../assets/mobileonboarding/phoneicon.png"
                                alt=""><span>{{ item.pastorPhone }}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 mt-3 d-flex">
                    <div class=" mr-3  align-self-center cursor-pointer " data-target="#branches" data-toggle="modal"
                      data-whatever="@fat" @click.prevent="editBranch(item)">
                      <i class="fa fa-pencil text-secondary"></i>
                    </div>
                    <div class="  align-self-center cursor-pointer" @click.prevent="showConfirmModalBranch(item.id)">
                      <i class="pi pi-trash text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- modal for other info -->
          <el-dialog style="border-radius: 20px;" v-model="displayOtherInfo"
            :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`" top>
            <div class=" row justify-content-center ">
              <div class="text-head font-weight-600 h2 text-center col-11 col-md-10">
                Add other Information
              </div>
              <div class="col-11 col-md-10 pt-2">
                <div class="row">
                  <div class="col-md-11 col-12">
                    <div class="form-cover w-100">
                      <form class="mr-lg-0">
                        <div class="form-group">
                          <label for="recipient-title" class="col-form-label">Title</label>
                          <el-input type="text" class="w-100" id="title" placeholder="Enter Title here"
                            v-model="otherInfo.title" />
                        </div>
                        <div class="form-group">
                          <label for="message-text" class="col-form-label">Information</label>
                          <el-input :rows="2" type="textarea" placeholder="Write details here" class="w-100"
                            id="message-text" v-model="otherInfo.details"></el-input>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-11 col-12 ml-md-3 mb-4 text-center text-lg-right">

                    <el-button round size="large" class="primary-bg w-100 border-0 text-white"
                      @click="otherInfoDetails">
                      Save
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-dialog>

          <!-- modal -->
          <el-dialog style="border-radius: 20px" v-model="displayPastorForm"
            :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`" top>
            <div class=" row justify-content-center ">
              <div class="text-head font-weight-600 h2 text-center col-11 col-md-10">
                Add Pastor/Minister
              </div>
              <div class="col-11 col-md-10 pt-2">
                <div class="row">
                  <div class="col-md-7 mt-4 col-12">
                    <div class="form-cover w-100">
                      <form class="mr-5 mr-md-0 mr-lg-0">
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Name</label>
                          <el-input type="text" class="w-100" id="recipient-name" placeholder="Enter Pastor's name"
                            v-model="pastorDetails.name" />
                        </div>
                        <div class="form-group">
                          <label for="recipient-name" class="col-form-label">Position</label>
                          <el-input type="text" class="w-100" id="recipient-name" placeholder="Enter Pastor's Position"
                            v-model="pastorDetails.title" />
                        </div>

                        <div class="form-group">
                          <label for="message-text" class="col-form-label">About</label>
                          <el-input class="w-100" type="textarea" :rows="2" id="message-text"
                            v-model="pastorDetails.bio" placeholder="Short bio"></el-input>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-md-5 mt-3 col-12">
                    <div class="col-md-11">
                      <img v-if="!pastorDetails.photo" src="../../../assets/people/phone-import.svg"
                        alt="Uploaded Image" class="w-100 h-100 img-responsive" />
                      <img v-else :src="pastorDetails.photo" class="w-100 h-100 img-responsive" />
                    </div>
                    <div class="custom-file col-md-11 mt-2 submit-img">
                      <input type="file" class="custom-file-input" id="customFile" @change="uploadFile" />
                      <label class="custom-file-label px-4" for="customFile"></label>
                    </div>

                  </div>
                </div>
                <div class="row mr-3 mr-md-0 mr-lg-0">
                  <div class="col-md-8 col-12 text-md-left mt-2">
                    <div class="ml-4">
                      <p class="font-weight-bold">Social media Handles</p>
                    </div>
                    <div class="row ml-2" v-for="(item, index) in pastorSocialMedia" :key="index">
                      <div class="col-md-2">
                        <div v-if="item.name === 'facebook'" class="d-flex justify-content-center font-container">
                          <img src="../../../assets/mobileonboarding/facebook-icon.png" style="width: 78px" />
                        </div>
                        <div v-if="item.name === 'instagram'" class="d-flex justify-content-center font-container">
                          <img src="../../../assets/mobileonboarding/instagram-icon.png" style="width: 78px" />
                        </div>
                        <div v-if="item.name === 'twitter'" class="d-flex justify-content-center font-container">
                          <img src="../../../assets/mobileonboarding/twitter-icon.png" style="width: 78px" />
                        </div>
                      </div>
                      <div class="col-md-9 mr-2 p-md-0">
                        <div class="">
                          <el-input type="text" class=" mb-2 w-100" v-model="item.url"
                            :placeholder="`Enter your ${item.name} page url`" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4"></div>
                </div>
                <div class="row  mt-3 ">
                  <div class="col-md-9 mb-3 ml-2 mt-3 text-center">
                    <el-button size="large" round class="w-100 primary-bg text-white" @click="detailsForPastor">
                      Save
                    </el-button>
                  </div>
                  <div @click="closeModal" class="col-md-9 ml-2 mb-4 text-center">
                    Close
                  </div>
                </div>
              </div>
            </div>
          </el-dialog>

          <!-- modal for pastors branches-->
          <el-dialog style="border-radius: 20px;" v-model="displayBranch"
            :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`" top>
            <div class=" row justify-content-center ">
              <div class="text-head font-weight-600 h2 text-center col-md-11">
                Add Branch
              </div>
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-7 mt-4 col-12">
                    <div class=" w-100">
                      <form class="mr-5 mr-md-0 ">
                        <div class="form-group">
                          <div class="font-weight-600">Branch Name</div>
                          <el-input type="text" class="w-100" id="recipient-name" placeholder="Enter Branch's name"
                            v-model="branchDetails.branchName" />
                        </div>
                        <div class="form-group">
                          <div class="font-weight-600">Pastor’s Name</div>
                          <el-input type="text" class="w-100" id="recipient-name" placeholder="Enter Pastor's name"
                            v-model="branchDetails.pastorName" />
                        </div>
                        <div class="form-group">
                          <div class="font-weight-600">Pastor’s details</div>
                          <el-input class="w-100" type="textarea" :rows="2" id="message-text"
                            v-model="branchDetails.pastorDetails" placeholder="Short bio"></el-input>
                        </div>
                        <div class="form-group">
                          <div class="font-weight-600">Pastor’s Phone number</div>
                          <el-input type="text" class="w-100" id="recipient-name" placeholder="Enter Pastor's number"
                            v-model="branchDetails.pastorPhone" />
                        </div>
                        <div class="form-group">
                          <div class="font-weight-600">Address</div>
                          <el-input type="text" class="w-100" id="recipient-name" placeholder="Enter Address"
                            v-model="branchDetails.address" />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-md-5 mt-3 col-12">

                    <div class="col-md-11">
                      <img v-if="!image" src="../../../assets/people/phone-import.svg" alt="Uploaded Image"
                        class="w-100 h-100 img-responsive" />
                      <img v-else :src="image" class="w-100 h-100 img-responsive" />
                    </div>
                    <div class=" col-md-11 mt-2 custom-file submit-img">
                      <input type="file" class="custom-file-input" id="customFile" @change="uploadPastorPicture" />
                      <label class="custom-file-label px-4" for="customFile"></label>
                    </div>

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-11  mb-4 text-center text-lg-right">

                    <el-button round size="large" class="primary-bg w-100  text-white" @click="branchPost">
                      Save
                    </el-button>
                  </div>
                  <div @click="closeModal" class="col-md-11 mb-4 text-center ">
                    Close
                  </div>
                </div>
              </div>
            </div>
          </el-dialog>
          <div class="modal fade" id="branches" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Add Branch
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">

                </div>
                <div class="col-md-12 col-12 col-lg-12 mb-4 text-center text-md-right text-lg-right">
                  <el-button size="large" round data-dismiss="modal" class="mr-3 " @click="closeModal">Close</el-button>
                  <el-button round size="large" class="primary-bg  text-white" data-dismiss="modal" @click="branchPost">
                    Save
                  </el-button>
                </div>
              </div>
            </div>
          </div>
          <div class=" mt-5 col-12 col-sm-6 offset-sm-3" @click="saveSetUp">
            <el-button size="large" round class="w-100 text-white primary-bg">
              Save and continue
            </el-button>
          </div>
          <div @click="skip" class=" my-3 mb-5 primary--text text-center  col-12 col-sm-6 offset-sm-3">
            Skip for now
          </div>
        </div>
      </div>

    </div>
    <el-dialog style="border-radius: 20px;" v-model="displayVideo"
      :width="mdAndUp || lgAndUp || xlAndUp ? `50%` : `90%`" top>
      <div class=" row justify-content-center ">
        <div class="col-md-12 ">
          <iframe width="100%" height="316" :src="videoURL"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "@/gateway/backendapi";
import store from "../../../store/store";
import stopProgressBar from "../../../services/progressbar/progress";
import { ElMessage, ElMessageBox } from "element-plus";
import deviceBreakpoint from "../../../mixins/deviceBreakpoint";
// import { useStore } from "vuex"

export default {
  props: [],
  setup(props, context) {
    // let store = useStore()
    const { lgAndUp, xlAndUp, mdAndUp } = deviceBreakpoint();
    const pastors = ref([]);
    const pastorDetails = ref({});
    const pastorsName = ref("");
    const pastorsEmail = ref("");
    const displayVideo = ref(false);
    const videoURL = ref("https://www.youtube.com/embed/X4Gtz6T4HLM?si=u9VJk_FsKC8nPzKR");
    const pastorSocialMedia = ref([
      {
        name: 'facebook',
        url: ''
      }, {
        name: 'twitter',
        url: ''

      }, {
        name: 'instagram',
        url: ''
      }
    ])
    // const pastorFacebook = ref("")
    // const pastorInstagram = ref("")
    // const pastorTwitter = ref("")
    const closePastorModal = ref("");
    const image = ref("");
    const pastorImage = ref("");
    const tenantId = ref("");
    const churchName = ref("");
    const phoneNumber = ref("");
    const address = ref("");
    const otherInfo = ref({ showFullMessage: false });
    const infoArray = ref([]);
    const closeTextArea = ref("");
    const pastorPayload = ref([]);
    const branchDetails = ref({});
    const churchBranches = ref([]);
    const displayPastorForm = ref(false)
    const displayOtherInfo = ref(false)
    const displayBranch = ref(false)


    const pastorForm = () => {
      displayPastorForm.value = true
    }
    const otherInfoForm = () => {
      displayOtherInfo.value = true
    }
    const watchVideo = () => {
      displayVideo.value = true
    }
    const saveSetUp = async () => {


      infoArray.value.map(i => {
        if (!i.customAboutId) delete i.showFullMessage
      })



      const formObj = {
        churchName: churchName.value,
        address: address.value,
        phoneNumber: phoneNumber.value,
        abouts: infoArray.value
      }
      // const formData = new FormData();
      // formData.append("churchName", churchName.value);
      // formData.append("address", address.value);
      // formData.append("phoneNumber", phoneNumber.value);
      // formData.append("abouts", infoArray.value);
      // formData.append("pastors", pastors.value);
      // console.log(churchName.value)
      // console.log(phoneNumber.value)
      // console.log(infoArray.value);
      // console.log(pastors.value);
      // formData.append("logo", payload.logo)

      try {
        const response = await axios.put(
          "/mobile/v1/Profile/UpdateChurchProfile",
          formObj
        );
        if (response) {
          ElMessage({
            type: "success",
            message: "Church Profile Updated Successfully",
            duration: 5000,
          });
          let changeState = {
            tab: true,
            churchSetup: false,
            socialMedia: true,
            appBranding: false,
            donationForm: false,
            subscription: false
          }
          context.emit('saved-churchsetup', changeState)
        }
        console.log(response);
      } catch (error) {
        stopProgressBar();
        if (error.toString().toLowerCase().includes('network error')) {
          ElMessage({
            type: "warning",
            message: "Network, Please ensure you have a strong internet connection",
            duration: 5000,
          });
        } else if (error.toString().toLowerCase().includes('timeout')) {
          ElMessage({
            type: "warning",
            message: "Request took too long to respond, please try again",
            duration: 5000,
          });
        }
        console.log(error);
      }
    };

    const detailsForPastor = async () => {
      pastorSocialMedia.value.forEach(i => {
        if (i.socialMediaId && i.url) {
          pastorPayload.value.push(`${i.socialMediaId},${i.name},${i.url},${i.owner}`)
        }
        if (!i.socialMediaId && i.url) {
          pastorPayload.value.push(`new,${i.name},${i.url},new`)
        }
      })

      let formData = new FormData()
      formData.append("name", pastorDetails.value.name)
      formData.append("title", pastorDetails.value.title)
      formData.append("bio", pastorDetails.value.bio)
      formData.append("photo", image.value)
      if (pastorDetails.value.pastorId) {
        formData.append("pastorId", pastorDetails.value.pastorId)
      }
      else {
        pastors.value.push({
          name: pastorDetails.value.name,
          title: pastorDetails.value.title,
          bio: pastorDetails.value.bio,
          photo: pastorDetails.value.photo,
          socialMedia: pastorSocialMedia.value
        });
      }
      pastorPayload.value.forEach(i => {
        formData.append("socialMedia", i)
      })

      try {
        let res = await axios.post('/mobile/v1/profile/createoreditpastor', formData)
        console.log(res.data)
        displayPastorForm.value = false
        // pastors.value = []
      }
      catch (error) {
        console.log(error)
        displayPastorForm.value = false
      }

      pastorPayload.value = []
      pastorDetails.value = {}
      pastorSocialMedia.value = [
        { name: 'facebook', url: '' },
        { name: 'twitter', url: '' },
        { name: 'instagram', url: '' }
      ]

    };

    const otherInfoDetails = async () => {
      console.log(otherInfo.value, 'gsgggsg')
      if (otherInfo.value.customAboutId) {
        try {
          const data = await axios.put('/mobile/v1/Profile/UpdateCustomAboutChurch', otherInfo.value)
          console.log(data, 'ggggg');
          // infoArray.value.push(data.data);

          displayOtherInfo.value = false

          otherInfo.value = {}
        }
        catch (error) {
          console.log(error);
        }
      } else {
        infoArray.value.push(otherInfo.value);
        otherInfo.value = {};
        displayOtherInfo.value = false
      }

    };

    const editPastor = (id, index) => {
      console.log(id, 'fdd');
      displayPastorForm.value = true
      pastorDetails.value = pastors.value[index]
      // pastorSocialMedia.value = pastors.value[index].socialMedia
      if (pastors.value[index] && pastors.value[index].socialMedia.length > 0) {
        if (pastors.value[index].socialMedia.find(i => i.name.trim().toLowerCase() === "facebook")) {
          pastorSocialMedia.value[0] = pastors.value[index].socialMedia.find(i => i.name.trim().toLowerCase() === "facebook")
        } else {
          pastorSocialMedia.value[0] = {
            name: 'facebook',
            url: ''
          }
        }
        if (pastors.value[index].socialMedia.find(i => i.name.trim().toLowerCase() === "twitter")) {
          pastorSocialMedia.value[1] = pastors.value[index].socialMedia.find(i => i.name.trim().toLowerCase() === "twitter")
        } else {
          pastorSocialMedia.value[1] = {
            name: 'twitter',
            url: ''
          }
        }
        if (pastors.value[index].socialMedia.find(i => i.name.trim().toLowerCase() === "instagram")) {
          pastorSocialMedia.value[2] = pastors.value[index].socialMedia.find(i => i.name.trim().toLowerCase() === "instagram")
        } else {
          pastorSocialMedia.value[2] = {
            name: 'instagram',
            url: ''
          }
        }


      }
      // detailsForPastor(id)
    }

    const uploadFile = (e) => {
      image.value = e.target.files[0];
      pastorDetails.value.photo = URL.createObjectURL(image.value);
      console.log('uploaded', pastorDetails.value)
    };

    const getTenantId = async () => {
      if (store && store.getters && store.getters.currentUser && Object.keys(store.getters.currentUser).length > 0) {
        console.log(store.getters.currentUser);
        tenantId.value = store.getters.currentUser.tenantId;
      } else {
        try {
          const res = await axios.get("/api/Membership/GetCurrentSignedInUser");
          store.dispatch("setCurrentUser", res.data);
          console.log(res.data);
          tenantId.value = res.data.tenantId;
        } catch (err) {
          console.log(err.response);
        }
      }

      console.log(tenantId.value);
      axios
        .get("/mobile/v1/Profile/GetChurchProfile")
        .then((response) => {
          console.log(response);
          churchName.value = response.data.returnObject.churchName;
          phoneNumber.value = response.data.returnObject.phoneNumber;
          address.value = response.data.returnObject.address;
          pastors.value = response.data.returnObject.pastors.map(i => {
            return {
              name: i.name,
              title: i.title,
              photo: i.photoUrl,
              pastorId: i.pastorId,
              bio: i.bio,
              tenantId: i.tenantID,
              socialMedia: i.socialMedias
            }
          })
          infoArray.value = response.data.returnObject.customAbouts.map(i => {
            return {
              title: i.title,
              details: i.details,
              customAboutId: i.customAboutId
            }
          })
          churchBranches.value = response.data.returnObject.churchBranches.map(i => {
            return {
              id: i.id,
              address: i.address,
              branchName: i.branchName,
              pastorDetails: i.pastorDetails,
              pastorName: i.pastorName,
              pastorPhone: i.pastorPhone,
              pastorPictureUrl: i.pastorPicturUrl
            }
          })
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getTenantId();

    const uploadPastorPicture = (e) => {
      branchDetails.value.pastorPicture = e.target.files[0];
      image.value = URL.createObjectURL(branchDetails.value.pastorPicture);

      console.log('uploaded', branchDetails.value)
    };
    const branchPost = async () => {
      let formData = new FormData()
      formData.append("branchName", branchDetails.value.branchName ? branchDetails.value.branchName : '')
      formData.append("pastorName", branchDetails.value.pastorName ? branchDetails.value.pastorName : '')
      formData.append("pastorDetails", branchDetails.value.pastorDetails ? branchDetails.value.pastorDetails : '')
      formData.append("pastorPhone", branchDetails.value.pastorPhone ? branchDetails.value.pastorPhone : '')
      formData.append("address", branchDetails.value.address ? branchDetails.value.address : '')
      formData.append("pastorPicture", branchDetails.value.pastorPicture ? branchDetails.value.pastorPicture : '')

      if (branchDetails.value.id) {
        formData.append("id", branchDetails.value.id)
        editChurchBranch(formData)
      } else {
        SaveChurchBranch(formData)
      }
      closePastorModal.value.ref.setAttribute("data-dismiss", "modal");
      branchDetails.value = {}
      image.value = ""
    }

    const SaveChurchBranch = async (formData) => {
      try {
        let res = await axios.post('/mobile/v1/Profile/CreateOrEditBranch', formData)
        const displayChurchBranch = {
          branchName: res.data.returnObject.branchName,
          pastorName: res.data.returnObject.pastorName,
          pastorPhone: res.data.returnObject.pastorPhone,
          details: res.data.returnObject.pastorDetails,
          address: res.data.returnObject.address,
          pastorPicture: res.data.returnObject.pastorPicturUrl
        }
        churchBranches.value.unshift(displayChurchBranch)
        ElMessage({
          type: "success",
          message: `${res.data.response}`,
          duration: 5000,
        });

      }
      catch (error) {
        console.log(error)
        ElMessage({
          type: "error",
          message: `${res.data.response}`,
          duration: 5000,
        });
      }
    }

    const editChurchBranch = async (item) => {
      console.log(item);
      branchDetails.value = item
      image.value = item.pastorPicturUrl
      try {
        let res = await axios.post('/mobile/v1/Profile/CreateOrEditBranch', branchDetails.value)
        ElMessage({
          type: "success",
          message: `${res.data.response}`,
          duration: 5000,
        });
      } catch (error) {
        console.log(error)
        ElMessage({
          type: "error",
          message: `Failed ${res.data.response}`,
          duration: 5000,
        });
      }
    }

    const clearChurchBranch = () => {
      branchDetails.value = {};
      image.value = ""
      displayBranch.value = true
    }
    const setChurchBranch = (item) => {
      branchDetails.value = item;
    }

    const editBranch = (item) => {
      console.log(item, 'edit itme');
      branchDetails.value = item;
      image.value = item.pastorPictureUrl
    }
    const editInfo = (item) => {
      console.log(item, 'edit itme');
      otherInfo.value = item
      displayOtherInfo.value = true

    }
    //  const imageEdit = (e) => {
    //   image.value = e.target.files[0];
    //   url.value = URL.createObjectURL(image.value);
    //   branchDetails.value.pastorPictureUrl = URL.createObjectURL(image.value);
    // };
    const skip = () => {
      if (churchName.value && address.value && phoneNumber.value && pastors.value.length > 0 && infoArray.value.length > 0) {
        let changeState = {
          tab: true,
          churchSetup: false,
          socialMedia: true,
          appBranding: false,
          donationForm: false
        }
        context.emit('saved-churchsetup', changeState)
      } else {
        let changeState = {
          // tab: true,
          churchSetup: false,
          socialMedia: true,
          appBranding: false,
          donationForm: false
        }
        context.emit('saved-churchsetup', changeState)
      }
    }

    const showConfirmModal = (id, index) => {
      ElMessageBox.confirm(
        "Are you sure you want to proceed?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "error",
        }
      )
        .then(() => {
          deletePastor(id, index);
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
            duration: 5000,
          });
        });
    };

    const deletePastor = (id, index) => {
      if (!id) {
        pastors.value.splice(index, 1);
      } else {
        axios
          .delete(`/mobile/v1/Profile/DeletePastor?pastorId=${id}`)
          .then((res) => {
            ElMessage({
              type: "success",
              message: `${res.data.response}`,
              duration: 5000,
            });
            pastors.value = pastors.value.filter(
              (item) => item.pastorId !== id
            );

          })
          .catch((err) => {
            console.log(err)
            ElMessage({
              type: "error",
              message: "An error occurred, please try again",
              duration: 5000,
            });
          });
      }

    };
    // Delete church branch 
    const deleteBranch = (id) => {
      if (id) {
        console.log(id, 'check delete');
        axios
          .delete(`/mobile/v1/Profile/removeBranch?branchId=${id}`)
          .then((res) => {
            ElMessage({
              type: "success",
              message: `${res.data.response}`,
              duration: 5000,
            });
            churchBranches.value = churchBranches.value.filter(
              (item) => item.id !== id
            );
          })
          .catch((err) => {
            console.log(err)
            ElMessage({
              type: "error",
              message: "An error occurred, please try again",
              duration: 5000,
            });
          });
      }


    }

    const showConfirmModalBranch = (id) => {
      ElMessageBox.confirm(
        "Are you sure you want to proceed?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "error",
        }
      )
        .then(() => {
          deleteBranch(id);
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
            duration: 5000,
          });
        });
    };

    const showConfirmModalAbout = (id, index) => {
      ElMessageBox.confirm(
        "Are you sure you want to proceed?",
        "Confirm delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "error",
        }
      )
        .then(() => {
          deleteAbout(id, index);
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
            duration: 5000,
          });
        });
    };

    const deleteAbout = (id, index) => {
      if (id && infoArray.value) {
        axios
          .delete(`/mobile/v1/Profile/DeleteCustomAbout?customAboutId=${id}`)
          .then((res) => {
            console.log(res);
            ElMessage({
              type: "success",
              message: `${res.data.response}`,
              duration: 5000,
            });
            infoArray.value = infoArray.value.filter(
              (item) => item.customAboutId !== id
            );

          })
          .catch((err) => {
            console.log(err)
            ElMessage({
              type: "success",
              message: "An error occurred, please try again",
              duration: 5000,
            });
          });
      } else {
        infoArray.value.splice(index, 1);
      }
    };

    const closeModal = () => {
      pastorPayload.value = []
      pastorDetails.value = {}
      pastorSocialMedia.value = [
        { name: 'facebook', url: '' },
        { name: 'twitter', url: '' },
        { name: 'instagram', url: '' }
      ]
      displayPastorForm.value = false
    }

    return {
      pastors,
      displayVideo,
      videoURL,
      saveSetUp,
      pastorDetails,
      pastorsName,
      pastorsEmail,
      detailsForPastor,
      closePastorModal,
      uploadFile,
      displayPastorForm,
      lgAndUp, xlAndUp, mdAndUp,
      image,
      pastorImage,
      tenantId,
      churchName,
      phoneNumber,
      otherInfo,
      otherInfoDetails,
      infoArray,
      closeTextArea,
      address,
      otherInfoForm,
      displayOtherInfo,
      displayBranch,
      skip,
      showConfirmModal,
      showConfirmModalAbout,
      pastorSocialMedia,
      editPastor,
      pastorPayload,
      closeModal,
      branchDetails,
      branchPost,
      uploadPastorPicture,
      churchBranches,
      editBranch,
      editInfo,
      SaveChurchBranch,
      editChurchBranch,
      deleteBranch,
      showConfirmModalBranch,
      clearChurchBranch,
      setChurchBranch,
      pastorForm,
      watchVideo
    };
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Raleway:wght@100..900&family=Roboto:wght@100&display=swap');

/* * {
    font-family: Poppins;
} */

.text-head {
  font-family: Raleway !important;
}

.text-font {
  font-family: Poppins;
}

.setup {
  color: #031c39;
  /* font: normal normal 800 30px/40px Nunito Sans; */
}

.check-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #136acd;
}

.pi {
  color: #fff;
  margin-top: 6px;
  margin-left: 2px;
}

.btn-button {
  border-radius: 25px !important;
  width: 40%;
  height: 25px;
  line-height: 10px;
}

.bg-image {
  background-image: url("../../../assets/mobileonboarding/groupMain.svg");
  /* height: 500px;  */
  /* background-position: center;  */
  background-repeat: no-repeat;
  background-size: cover;
  /* background: transparent linear-gradient(180deg, #2E67CE 0%, #690C7F 100%) 0% 0% no-repeat padding-box; */
  min-height: 100vh;
}

.image-dis {
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-btn {
  background: #f1faff 0% 0% no-repeat padding-box;
  border: 0.20000000298023224px solid #7894a6;
  border-radius: 23px;
  opacity: 1;
}

.next-text {
  font: normal normal bold 16px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #136acd;
  opacity: 1;
}

.step {
  font: normal normal bold 18px/24px Nunito Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

/* Add pastor style */
.modal-title {
  margin-left: 2rem;
}

.form-cover {
  margin-left: 1.5rem !important;
  width: 100%;
}

.form-group {
  margin-top: 0rem !important;
}

.form-group label {
  padding-top: 0;
  font-weight: bold;
}

.form-control {
  width: 98%;
}

.select option {
  width: 150px;
}

.upload-Btn {
  border-radius: 25px;
  height: 30px;
  width: 90px;
  line-height: 15px;
}

.container-img {
  width: 100%;
  padding-bottom: 24px;
  /* height: 35vh; */

  border-radius: 27px !important;
  background: rgb(248, 248, 249);
  margin-top: 0.3rem;
}

.photo-box {
  border-radius: 27px;
  width: 87%;
  margin-left: 11px;
  height: 93px;
  background-color: rgb(100%, 100%, 100%);
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.social-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* end add pastor detail */
}

.bg-mission {
  background: #dedede61 0% 0% no-repeat padding-box;
  border: 1px solid #d2cfcf5c;
  border-radius: 24px;
  opacity: 0.64;
  height: 30px;
  padding-right: 50px;
}

.mission {
  font: normal normal bold 16px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #002044;
}

.edit {
  font: normal normal bold 16px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #136acd;
  cursor: pointer;
}

.pastors-name {
  font: normal normal 600 16px/22px Nunito Sans;
}

.edit-pastor-details {
  font: normal normal bold 16px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #136acd;
  cursor: pointer;
}

.btnIcons {
  color: #136acd;
  background-color: #dde2e6;
  border-radius: 40px;
  border: none;
  padding: 7px;
  text-align: center;
}

.pastorname {
  font: normal normal 700 16px/22px Nunito Sans;
  letter-spacing: 0px;
  color: #020e1c;
}

.submit-img {
  width: 87%;
  margin-left: 12px;
}

.skip-text {
  background: rgba(0, 0, 0, 0.707);
  position: fixed;
  top: 34em;
  width: 20%
}

.person-image {
  width: 50%;
  margin: auto;
}
</style>
